import React from "react";

const ChangePhoneNumberContext = React.createContext();
const ChangePhoneNumberContextSetState = React.createContext();

function ChangePhoneNumberProvider({ children }) {
  const [phoneNumber , setPhoneNumber] = React.useState('')
  const [verificationCode , setVerificationCode] = React.useState('')
  const [showCodeBox , setShowCodeBox] = React.useState(false)


  return (
    <ChangePhoneNumberContext.Provider value={{phoneNumber , verificationCode , showCodeBox}}>
      <ChangePhoneNumberContextSetState.Provider 
        value = {{setPhoneNumber, setVerificationCode , setShowCodeBox}} 
      >
        {children}
      </ChangePhoneNumberContextSetState.Provider>
    </ChangePhoneNumberContext.Provider>
  );
}

function ChangePhoneNumberState() {
  const {phoneNumber} = React.useContext(ChangePhoneNumberContext);

  if (phoneNumber === undefined) {
    throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
  }

  return {phoneNumber};
}

function ChangePhoneNumberSetState() {
  const {setPhoneNumber} = React.useContext(ChangePhoneNumberContextSetState);

  if (setPhoneNumber === undefined) {
    throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
  }

  const setChangePhoneNumberMethod = (phoneNumber) => {
      setPhoneNumber('+' + phoneNumber);
  };

  return {setChangePhoneNumberMethod};
}


function ChangeVerificationCodeState() {
  const {verificationCode} = React.useContext(ChangePhoneNumberContext);

  if (verificationCode === undefined) {
    throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
  }

  return {verificationCode};
}
// 



function ChangeVerificationCodeSetState() {
    const { setVerificationCode } = React.useContext(ChangePhoneNumberContextSetState);

    if (setVerificationCode === undefined) {
      throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
    }
    
    const setVerificationCodeMethod = (ver) => {
      setVerificationCode(ver);
    };

    return { setVerificationCodeMethod };
}


// 
function ChangeShowCodeBoxState() {
  const {showCodeBox} = React.useContext(ChangePhoneNumberContext);

  if (showCodeBox === undefined) {
    throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
  }

  return {showCodeBox};
}
// 



function ChangeShowCodeBoxSetState() {
    const { setShowCodeBox } = React.useContext(ChangePhoneNumberContextSetState);

    if (setShowCodeBox === undefined) {
      throw new Error("render <ChangePhoneNumberProvider /> at top of the tree");
    }
    
    const setChangeShowCodeBoxMethod = (codeBox) => {
      setShowCodeBox(codeBox);
    };

    return { setChangeShowCodeBoxMethod };
}



export { 
  ChangePhoneNumberState,
  ChangePhoneNumberSetState,
  ChangeVerificationCodeState,
  ChangeVerificationCodeSetState,
  ChangeShowCodeBoxState,
  ChangeShowCodeBoxSetState
};

export default ChangePhoneNumberProvider;
