import {useConfig} from "../ConfigProvider/ConfigProvider";
import { DialogSetState} from './ChangeInfo.js'
function CheckInfoUser() {
    const {user} = useConfig()
    const {setDialogChangeInfo} = DialogSetState()
    const checkInfoUser = () => {
        if(!user?.supplier_info?.company_name||!user?.supplier_info?.category_id){
            setTimeout(() => {
              setDialogChangeInfo(true)
            } , 3000)
          }
    }

    return checkInfoUser;
}

export default CheckInfoUser
