import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { IoIosArrowBack } from "react-icons/io";
import styles from "./Aside.module.css";
import {ReactComponent as ExplorerLogo} from "../../../Assets/explorerOfOman.svg";
import { Resizable } from "re-resizable";
import Handle from "./Handle";

import Logo from "../../../Assets/NAFUNTechLogoWhite.png";
import { useLanguage } from "../../../Provider/langProvider/LangProvider";

const Aside = ({ rtl, toggled, handleToggleSidebar, breakPoint, hide , children , setCollapsed , collapsed}) => {
  // const [collapsed, setCollapsed] = React.useState(false);
  const [size, setsize] = React.useState(270);
  const handleCollapsedChange = (boolian) => {
    setCollapsed(boolian);
    boolian ? setsize(80) : setsize(270);
  };
  return (
    <Resizable
      style={hide ? { display: "none" } : {}}
      handleComponent={{ left: <Handle />, right: <Handle /> }}
      handleWrapperClass={styles.handel}
      enable={{
        top: false,
        right: !rtl,
        bottom: false,
        left: !!rtl,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      className={`${styles.resizable} ${rtl} ${toggled&&styles.active} ${rtl&&styles.rtl}`}
      minWidth="80px"
      size={{ width: size }}
      maxWidth={"300px"}
      onResizeStop={(e, direction, ref, d) => {
        const newSize = size + d.width;
        setsize(newSize);
        if (newSize < 100) {
          setCollapsed(true);
        } else if (newSize > 200) {
          setCollapsed(false);
        }
      }}
      defaultSize={{ width: 200 }}
    >
      <div
        className={
          collapsed
            ? `${styles.collapsed} ${styles.sidebarwrapper}`
            : styles.sidebarwrapper
        }
      >
        <span
          className={styles.arrowback}
          onClick={() => handleCollapsedChange(!collapsed)}
        >
          <IoIosArrowBack />
        </span>
        <ProSidebar
          className={styles.sidebar}
          image={false}
          rtl={rtl}
          collapsed={collapsed}
          toggled={toggled}
          breakPoint={breakPoint}
          onToggle={handleToggleSidebar}
        >
          <SidebarHeader>
            <div
              className={`${styles.header} ${collapsed ? styles.collapsedHeader : ""}`}
            >
              <ExplorerLogo  className={styles.image} />
              {/*<img src={image} alt="" className={styles.image} />*/}
              {/*<span>Magifits</span>*/}
            </div>
          </SidebarHeader>

          <SidebarContent className="CustomScrollbar">
            {children}
          </SidebarContent>

          {!collapsed && (
            <SidebarFooter style={{ textAlign: "center" }}>
              <div
                className="sidebar-btn-wrapper"
                style={{
                  padding: "20px 24px",
                }}
              >
                <a
                  href="https://nafuntech.com/"
                  target="_blank"
                  className={styles.sidebar_btn}
                  rel="noopener noreferrer"
                >
                  <span>Powered by</span>
                  <img src={Logo} alt="" />
                </a>
              </div>
            </SidebarFooter>
          )}
        </ProSidebar>
        {/* <div className={styles.arrowbackwrapper}>
        </div> */}
      </div>
    </Resizable>
  );
};

export default Aside;
