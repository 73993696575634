import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useLanguage} from 'Provider/langProvider/LangProvider';
import React, {memo} from 'react';
import rtlPlugin from 'stylis-plugin-rtl';

const rtlTheme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  typography: {
    fontFamily: [
      'iransans',
      'vazir',
      'homa',
      "system-ui",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  // palette: {
  //   warning: {
  //     main: '#00FF00FF'
  //   }
  // },
})
const ltrTheme = createTheme({
  direction: 'ltr',
  typography: {
    fontFamily: [
      'iransans',
      'vazir',
      'homa',
      "system-ui",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  // palette: {
  //   warning: {
  //     main: '#00FF00FF'
  //   }
  // },
})
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
  prepend: false
})
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [],
  prepend: false
})

/**
 * Wrap this around your component and set the main div dir to rtl
 * */
const Rtl = ({children}: {children: React.ReactNode }) => {
  // Create rtl cache
  const {dir} = useLanguage()

  return (
      <CacheProvider value={dir === 'ltr' ? cacheLtr : cacheRtl}>
        <ThemeProvider theme={dir === 'ltr' ? ltrTheme : rtlTheme}>
            {children}
        </ThemeProvider>
      </CacheProvider>
  )
};

export const RTL = memo(Rtl)
