import {LogoutActions} from '../LoginProvider'
import {useHistory} from 'react-router-dom'

function ConfigureRefreshFetch() {
  const {Logout} = LogoutActions()
  const history = useHistory()

  const configureRefreshFetch = (configuration) => {

    const {RefreshToken, shouldRefreshToken, fetch} = configuration
    let refreshingTokenPromise = null

    return (url, options, showError, showSuccess) => {

      if (refreshingTokenPromise !== null) {
        return (
          refreshingTokenPromise
            .then(() => fetch(url, options, showError, showSuccess))
            // Even if the refreshing fails, do the fetch so we reject with
            // error of that request
            .catch(() => fetch(url, options, showError, showSuccess))
        )
      }

      return fetch(url, options, showError, showSuccess).catch(error => {
        if (shouldRefreshToken(error)) {
          if (refreshingTokenPromise === null) {
            refreshingTokenPromise = new Promise((resolve, reject) => {
              RefreshToken()
                .then(() => {
                  refreshingTokenPromise = null
                  resolve()
                })
                .catch(refreshTokenError => {
                  refreshingTokenPromise = null
                  Logout()
                  history.push('/login')
                  reject(refreshTokenError)
                })
            })
          }

          return refreshingTokenPromise

            .catch(() => {
              // If refreshing fails, continue with original error
              throw error
            })
            .then(() => fetch(url, options, showError, showSuccess))

        } else {

          throw error

        }

      })
    }
  }
  return configureRefreshFetch
}

export default ConfigureRefreshFetch
