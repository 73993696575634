import {useEffect} from "react";
import { Fetch } from "../Provider/api"
import {useLang} from "../Provider/langProvider/LangProvider";

const useClosureFetch = (url,defaultfetch=false) => {
  const fetcher = Fetch()
  const BASE_URL = url || process.env.REACT_APP_BASE_URL + "/api/";
  const myAbortController = new AbortController();
  const lang = useLang()
  // const checkInfoUser = CheckInfoUser()

  useEffect(() => {
    return () => myAbortController.abort();
  }, []);

  function Fetched(url,options={}) {
    options.signal = myAbortController.signal
    options.headers = {...options.headers,lang:lang}
    // try {
    // checkInfoUser()
      if(defaultfetch){return fetch(BASE_URL + url, options)}
      return fetcher(BASE_URL + url, options)
  }

  return Fetched;
};

export default useClosureFetch;
