import React, {useEffect} from 'react';
import {ReactComponent as Location} from "../../Assets/locationmarker.svg";
import useClosureFetch from "../../hooks/useClosureFetch";
import {useWhyDidYouUpdate} from "../../hooks/useWhyDidYouUpdate";
import {useLang} from "../../Provider/langProvider/LangProvider";
import styles from './Mapwrapper.module.css'

function checkBadAddressComponents(item){
  return item === 'country'  || item === 'plus_code'  || item === 'locality' || item === 'administrative_area_level_2' || item === 'administrative_area_level_3' || item === 'administrative_area_level_4'|| item === 'administrative_area_level_5'|| item === 'administrative_area_level_6'
}
function form_address(inp) {
  const fdsa = inp.reverse().reduce(function (prev, item) {
    if (item.types.some(checkBadAddressComponents)) {
      return prev
    }
    console.log(item)
    return prev + ' ' + item.long_name??''
  }, '')
  console.log(fdsa)
  return fdsa
}
const Getcenterwithmarker = ({map, setlocation, setlocationname,setloading}) => {
  const lang = useLang()
  const fetch = useClosureFetch()
  useWhyDidYouUpdate('getcenterwitmarker',{map, setlocation, setlocationname,setloading})
  // const geocoder = new window.google.maps.Geocoder();
  function getCenter() {
    let a = map.getCenter()
    const lat = a?.lat?.() || a?.lat || false;
    const lng = a?.lng?.() || a?.lng || false;
    return lat && lng ?{lat: lat, lng: lng}:null
  }

  function geocodeLatLng(latlng) {
    setloading?.(true)
    // geocoder
    //   .geocode({location: latlng})
    // fetch("google_map", {
    //   body: JSON.stringify({putKey:"a",Request:"https://maps.googleapis.com/maps/api/geocode/json?key=&latlng=" + latlng.lat + "," +latlng.lng+"&language="+lang}),
    //   method: "POST",
    // })
    //   .then((response) => {return JSON.parse(response.body)})
    //   .then((response) => {
    //     setloading?.(false)
    //     if (response.results[0]) {
    //       setlocationname({...response.results[0],betterAddress:form_address(response.results[0].address_components)});
    //     } else {
    //       console.log("No results found");
    //     }
    //   })
    //   .catch((e) => {
    //     setloading?.(false)
    //     console.log("Geocoder failed due to: " + e)
    //   });
  }

  useEffect( () => {
    let listner
    if(map){
      let center = getCenter()
      if (center){
        setlocation(center)
        geocodeLatLng(center)
      }
      listner = window.google.maps.event.addListener(map, 'dragend', async function (e) {
        let center = getCenter()
        if (center){
        setlocation(center)
        await geocodeLatLng(center)
        }
      });
    }
    return ()=>{
      window.google.maps.event.removeListener(listner)
    }
  }, [map])
  return (
    <Location
      className={styles.Location}
      onClick={setlocation?.bind(this, getCenter())}
      style={{
        color: 'red',
        cursor: 'pointer'
      }}/>
  );
};

export default Getcenterwithmarker;
