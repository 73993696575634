import React from "react";

const LoginUPassContext = React.createContext();
const LoginUPassContextSetState = React.createContext();

function LoginUPassProvider({ children }) {
  const [username , setUsername] = React.useState('')
  const [password , setPassword] = React.useState('')
  const [phoneNumber , setPhoneNumber] = React.useState(null)
  const [conformed , setConformed] = React.useState('')
  const [verPrefix , setVerPrefix] = React.useState('')
  const [forgetPass , setForgetPass] = React.useState(0)
  const [loading , setLoading] = React.useState(0)
  const [loadingGoogle , setLoadingGoogle] = React.useState(0)
  const [loadingFacebook , setLoadingFacebook] = React.useState(0)
  const [dialogDigitalCode , setDialogDigitalCode] = React.useState(false)
  const [dialogPassword , setDialogPassword] = React.useState(false)
  const [loadingLogout , setLoadingLogout] = React.useState(false)

  return (
    <LoginUPassContext.Provider value={{username, password, conformed , phoneNumber , verPrefix , forgetPass , loading , loadingGoogle , loadingFacebook , dialogDigitalCode , dialogPassword , loadingLogout}}>
      <LoginUPassContextSetState.Provider 
        value = {{
          setUsername ,
          setPassword ,
          setConformed ,
          setPhoneNumber,
          setVerPrefix ,
          setForgetPass,
          setLoading,
          setLoadingGoogle,
          setLoadingFacebook,
          setDialogDigitalCode,
          setDialogPassword,
          setLoadingLogout
        }} >
        {children}
      </LoginUPassContextSetState.Provider>
    </LoginUPassContext.Provider>
  );
}



function LoginUPassState() {
  const {
    username ,
    password ,
    conformed ,
    phoneNumber ,
    verPrefix ,
    forgetPass,
    loading,
    loadingGoogle,
    loadingFacebook,
    dialogDigitalCode,
    dialogPassword,
    loadingLogout
  } = React.useContext(LoginUPassContext);

  if (
    username === undefined ||
    password === undefined ||
    conformed === undefined ||
    phoneNumber === undefined ||
    verPrefix === undefined ||
    forgetPass === undefined || 
    loading === undefined ||
    loadingGoogle === undefined ||
    loadingFacebook === undefined ||
    dialogDigitalCode === undefined ||
    dialogPassword === undefined ||
    loadingLogout === undefined
  ) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return {
    username ,
    password ,
    conformed ,
    phoneNumber ,
    verPrefix ,
    forgetPass,
    loading,
    loadingGoogle,
    loadingFacebook,
    dialogDigitalCode,
    dialogPassword,
    loadingLogout
  };
}


// 
function LoginUPassSetState() {
  const {
    setUsername ,
    setPassword ,
    setConformed ,
    setPhoneNumber ,
    setVerPrefix ,
    setForgetPass,
    setLoading,
    setLoadingGoogle,
    setLoadingFacebook,
    setDialogDigitalCode,
    setDialogPassword,
    setLoadingLogout
  } = React.useContext(LoginUPassContextSetState);

  if (
    setUsername === undefined ||
    setPassword === undefined ||
    setConformed === undefined ||
    setPhoneNumber === undefined ||
    setVerPrefix === undefined  ||
    setForgetPass === undefined ||
    setLoading === undefined  ||
    setLoadingGoogle === undefined ||
    setLoadingFacebook === undefined ||
    setDialogDigitalCode === undefined ||
    setDialogPassword === undefined ||
    setLoadingLogout === undefined
  ) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return {
    setUsername ,
    setPassword ,
    setConformed ,
    setPhoneNumber ,
    setVerPrefix ,
    setForgetPass,
    setLoading,
    setLoadingGoogle,
    setLoadingFacebook,
    setDialogDigitalCode,
    setDialogPassword,
    setLoadingLogout
  };
}


// 
function UsernameSetState() {
  const { setUsername } = LoginUPassSetState();

  const setUsernameMethod = (username) => {
    setUsername(username);
  };

  return { setUsernameMethod };
}

function PasswordSetState() {
  const { setPassword } = LoginUPassSetState();

  const setPasswordMethod = (pass) => {
    setPassword(pass);
  };

  return { setPasswordMethod };
}

function ConformedSetState() {
  const { setConformed } = LoginUPassSetState();

  const setConformedMethod = (type) => {
    setConformed(type);
  };

  return { setConformedMethod };
}

function PhoneNumberSetState() {
  const { setPhoneNumber } = LoginUPassSetState();

  const setPhoneNumberMethod = (phone) => {
    setPhoneNumber('+' + phone);
  };

  return { setPhoneNumberMethod };
}

function VerPrefixSetState() {
  const { setVerPrefix } = LoginUPassSetState();

  const setVerPrefixMethod = (verfication) => {
    setVerPrefix(verfication);
  };

  return { setVerPrefixMethod };
}

function ForgetPassSetState() {
  const { setForgetPass } = LoginUPassSetState();

  const setForgetPassMethod = (reset) => {
    setForgetPass(+reset);
  };

  return { setForgetPassMethod };
}

function LoadingSetState() {
  const { setLoading } = LoginUPassSetState();

  const loadingMethod = (load) => {
    setLoading(load);
  };

  return { loadingMethod };
}

function LoadingGoogleSetState() {
  const { setLoadingGoogle } = LoginUPassSetState();

  const loadingGoogleMethod = (load) => {
    setLoadingGoogle(load);
  };

  return { loadingGoogleMethod };
}

function LoadingFacebookSetState() {
  const { setLoadingFacebook } = LoginUPassSetState();

  const loadingFacebookMethod = (load) => {
    setLoadingFacebook(load);
  };

  return { loadingFacebookMethod };
}

function setDialogDigitalCodeSetState() {
  const { setDialogDigitalCode } = LoginUPassSetState();

  const dialogDigitalMethod = (toggle) => {
    setDialogDigitalCode(toggle);
  };

  return { dialogDigitalMethod };
}

function setDialogPasswordSetState() {
  const { setDialogPassword } = LoginUPassSetState();

  const dialogPasswordMethod = (toggle) => {
    setDialogPassword(toggle);
  };

  return { dialogPasswordMethod };
}

function loadingLogoutSetState() {
  const {setLoadingLogout} = LoginUPassSetState()

  const loadingLogoutMethod = (load) => {
    setLoadingLogout(load)
  }

  return {loadingLogoutMethod}
}



export { 
  LoginUPassState,
  LoginUPassSetState,
  UsernameSetState ,
  PasswordSetState ,
  ConformedSetState,
  PhoneNumberSetState,
  VerPrefixSetState,
  ForgetPassSetState,
  LoadingSetState,
  LoadingGoogleSetState,
  LoadingFacebookSetState,
  setDialogDigitalCodeSetState,
  setDialogPasswordSetState,
  loadingLogoutSetState
};

export default LoginUPassProvider;
