import React from "react";
import {useJsApiLoader,} from "@react-google-maps/api";
import stylerer from "./stylerer";
import "./map.css";
import helper from "./Map";
import styles from './mapwrap.module.css'
import Getcenterwithmarker from "./getcenterwithmarker";
import MapJs from './MapJs'

const containerStyle = {
  width: "100%",
  height: "100%",
};

function MapComponent(props) {
  function wheelEvent(event) {
    var e = window.event || e; // old IE support
    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail))); //to know whether it was wheel up or down
    console.log(e,delta)
    map.setZoom(map.getZoom() + delta);
  }
  let cou_stylenname = 1;
  const stylerName = [
    "default",
    "aubergine",
    "dark",
    "night",
    "retro",
    "hiding",
    "silver",
  ];
  const stylerCount = stylerName.length;
  const routnigmode_names = [
    "driving",
    "walking",
    "bicycling",
    "BUS",
    "TRAIN",
    "SUBWAY",
    "RAIL",
    "TRAM",
  ];
  const routnig_transit_icones = [
    "directions_car",
    "directions_walk",
    "directions_bike",
    "directions_bus",
    "train",
    "subway",
    "directions_railway",
    "tram",
  ];
  const [map, setmap] = React.useState()
  let cou_routing = 1;
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_google_key
  })
  // to make map buttons make a div and position is the number in window.google.maps.ControlPosition
  const jsonMapMaker = {
    center: {lat:32.646527287826494, lng:51.66782292267515},
    zoom: 14,
    clickableIcons: false,
    options: {
      styles: stylerer["default"],
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      gestureHandling: "greedy",
      zoomControlOptions: {position: 8},
      fullscreenControlOptions: {position: 8},
      mapTypeControlOptions: {position: 6},
      scrollwheel: false,

    },
    clustererOptions: {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    },
    mapButtons: {
      locationButton: {
        element:
          "<div class='custom-map-control-button map_icon no_select material-icons'>gps_fixed</div>",
        position: 8,
        clickListner: helper.mark_curent_location.bind(helper),
      },
      styler: {
        element:
          "<div class='custom-map-control-button map_icon no_select material-icons'>format_paint</div>",
        position: 8,
        clickListner: (map, e) => {
          if (!e.target.classList.contains("active")) {
            e.target.classList.add("active");
            map.setOptions({styles: stylerer[stylerName[cou_stylenname]]});
            cou_stylenname = (cou_stylenname + 1) % stylerCount;
            setTimeout(() => {
              e.target.classList.remove("active");
            }, 500);
          } else {
            return;
          }
        },
      },
      routing: {
        element:
          "<div class='custom-map-control-button map_icon no_select material-icons'>directions_car</div>",
        position: 8,
        clickListner: (map, e) => {
          if (cou_routing >= 8) {
            cou_routing = 0;
            helper.mode = routnigmode_names[cou_routing];
          } else if (cou_routing > 2) {
            helper.mode = "transit";
            helper.transitmode = {modes: [routnigmode_names[cou_routing]]};
          } else {
            helper.mode = routnigmode_names[cou_routing];
          }
          e.target.innerText = routnig_transit_icones[cou_routing];
          cou_routing = cou_routing + 1;
        },
      },
    },
  };
  const onLoad = React.useCallback(function callback(map) {
    Object.keys(jsonMapMaker.mapButtons).map((el) => {
      const MapButton = document.createElement("div");
      MapButton.innerHTML = jsonMapMaker.mapButtons[el].element;
      map.controls[jsonMapMaker.mapButtons[el].position].push(MapButton);
      MapButton.addEventListener(
        "click",
        jsonMapMaker.mapButtons[el].clickListner.bind(this, map)
      );
    });
    helper.map = map;
    setmap(map);
    helper.mark_curent_location().then(
      res => {
        if (typeof res === 'object') props.setlocation(getlocation(res))
      }
    )
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
  }, []);
  return isLoaded ? (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative"
        }}
        onWheel={wheelEvent}
      >

        <MapJs
          mapContainerStyle={containerStyle}
          mapContainerClassName={styles.mapContainerClassName}
          zoom={jsonMapMaker.zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={helper.clickhandler.bind(helper)}
          clickableIcons={jsonMapMaker.clickableIcons}
          options={jsonMapMaker.options}
          />
        {map ?
          <Getcenterwithmarker map={map} setlocation={props.setlocation} setlocationname={props.setlocationname}/> : null}
      </div>
    )
    :
    (
      <></>
    );
}

MapComponent.defaultProps = {
  options: {styles: stylerer["default"]},
};

export default MapComponent;

export function getlocation(location) {
  const number = {}
  if (typeof location.lat === 'function') {
    number.lat = location.lat()
  } else {
    number.lat = location.lat
  }
  if (typeof location.lng === 'function') {
    number.lng = location.lng()
  } else {
    number.lng = location.lng
  }
  return number
}
