import * as React from 'react';
import {useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function GroupSelect({items, selectedItem, afterSelectHandler}: any) {

    const [selected, setSelected] = React.useState(null);
    const [open, setOpen] = React.useState(false);


    const handleClose = (item: any) => {
        setSelected(item)
        setOpen(false);
        afterSelectHandler(item)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        const founddedItems = items?.find((item: any) => item?.id === selectedItem)
        if (founddedItems) {
            setSelected(founddedItems)
        }
    }, [])

    // @ts-ignore

    function ListItem({item}: any) {
        let children = null;
        if (item?.children && item?.children?.length) {
            children = (
                <ul>
                    {item?.children?.map((i: any) => (
                        <ListItem item={i} key={i.id}/>
                    ))}
                </ul>
            );
        }

        return (
            <>
                <Accordion sx={{m: 1, minWidth: 100, border: 'none !important', padding: '0'}}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: `${!!item?.children?.length ? '1fr 2%' : '1fr'}`
                    }}>
                        <MenuItem onClick={() => handleClose(item)}>{item?.name}</MenuItem>
                        {
                            !!item?.children?.length ? (
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    sx={{border: 'none !important'}}
                                >
                                </AccordionSummary>
                            ) : null
                        }
                    </div>
                    <AccordionDetails sx={{border: 'none !important'}}>
                        {children}
                    </AccordionDetails>
                </Accordion>
            </>
        );
    }

    return (
        <div>
            <FormControl sx={{m: 1, margin: '0',width:'100%'}}>
                <Select open={open}
                        onOpen={handleOpen}
                        onClose={() => setOpen(false)}
                        value={'0'}
                >
                    <MenuItem style={{display: 'none'}} value={0} id="grouped-select"> {selected?.name}</MenuItem>
                    <div>
                        {items?.map((i: any) => (
                            <ListItem item={i} key={i.id}/>
                        ))}
                    </div>
                </Select>
            </FormControl>
        </div>
    );
}
