import {Fetch} from '../api'
import {SetStateByKey} from 'Provider/ConfigProvider/ConfigProvider'
import {ChangeInfoState, DialogSetState} from './ChangeInfo'
import {LoadingSetState} from '../UserNameAndPassword'
import {useConfig} from "../ConfigProvider/ConfigProvider";

function ChangeInformation() {

  const {
    companyName,
    companyCategory,
    companyPhone,
    companyEmail,
    companyAbout,
    companyLocation
  } = ChangeInfoState();

  const {setDialogChangeInfo} = DialogSetState();
  const {loadingMethod} = LoadingSetState()
  const setUser = SetStateByKey()
  const syncApp = useConfig()
  const fetch = Fetch()


  const changeInformation = () => {

    let body={};
    if(companyName!==""){
      body.company_name = companyName||syncApp?.user?.supplier_info?.company_name
    }
    if(companyPhone!==""){
      body.phone_numbe = companyPhone||syncApp?.user?.supplier_info?.phone_numbe
    }
    if(companyCategory!==""){
      body.category_id = companyCategory||syncApp?.user?.supplier_info?.category_id
    }
    if(companyAbout!==""){
      body.about = companyAbout||syncApp?.user?.supplier_info?.about
    }
    if(companyEmail!==""){
      body.email = companyEmail||syncApp?.user?.supplier_info?.email
    }
    if(companyLocation?.lat&&String(companyLocation?.lat)!==""){
      body.lat = String(companyLocation?.lat)
    }
    if(companyLocation?.lng&&String(companyLocation?.lng)!==""){
      body.long = String(companyLocation?.lng)
    }
    loadingMethod(1)
    fetch(`${process.env.REACT_APP_BASE_URL}/api/suppliers/${syncApp?.user?.supplier_info?.company_name?'update':'create'}`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => {
        setUser('user',{...syncApp.user,supplier_info:res.body.data?.supplier})
        setDialogChangeInfo(false)
        // setNameChangeInfoMethod('')
        // setUsernameChangeInfoMethod('')
        loadingMethod(0)
      })
      .catch(err => {
        loadingMethod(0)
      })
  }
  return changeInformation
}

export default ChangeInformation
