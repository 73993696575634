import React, {useState} from 'react'
import styles from './Supplementary.module.css'
import ChangeInformation from '../../Provider/ChangeInfo/ChangeInfoApi'
import {ChangeInfoState, DialogSetState} from '../../Provider/ChangeInfo/ChangeInfo'
import {useLanguage} from '../../Provider/langProvider/LangProvider'
import {reonce} from '../../helpers/helpers'
import {useLocation} from "react-router-dom";
import SimpleAccordion from "./components/MainMenu";
import useClosureFetch from "../../hooks/useClosureFetch";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import {LoginUPassState} from "../../Provider/UserNameAndPassword";
import {useConfig} from "../../Provider/ConfigProvider/ConfigProvider";

function Supplementary() {

  const fetch = useClosureFetch()
  const {dialog} = ChangeInfoState();
  const {setDialogChangeInfo} = DialogSetState();
  const changeInformation = ChangeInformation();
  const {dir} = useLanguage();
  const {loading} = LoginUPassState()
  const once = reonce(changeInformation);
  const {pathname} = useLocation()
  const [categories, setCategories] = useState(null)
  const syncApp = useConfig()
  const {t: commonT} = useTranslation('common');

  const dialogHideHanldler = () => {
    setDialogChangeInfo(false)
  }

  const fetchCategories = async () => {
    const {body: {data}} = await fetch('suppliers/supplier_categories', {
      method: 'post'
    })
    setCategories(data)
  }

  React.useEffect(() => {

    if (pathname === '/intro' || pathname === '/login' || pathname === '/admin') {
      dialogHideHanldler()
    }

  }, [pathname, dialog])

  React.useEffect(() => {
    fetchCategories()
  }, [dialog])

  const onSubmitHandler = (event) => {
    event.preventDefault();
    once()
  }
  console.log(syncApp?.user)
  console.log('syncApp?.user')
  return (
    dialog ?
      <React.Fragment>
        <div className={styles.dialog} style={{direction: dir}}>

          <div className={styles.container}>
            <div className={styles.textContainer}>
              <h1>{commonT('asideTitle')}</h1>
              <Typography sx={{color: 'text.secondary'}}> {commonT('asideDesc')}</Typography>
            </div>
            <form className={styles.formBox} onSubmit={onSubmitHandler}>
              <SimpleAccordion categories={categories}/>
              <div className={styles.buttonsContainer}>
                <button type='button' onClick={syncApp?.user?.supplier_info&&dialogHideHanldler}
                        className={`${styles.saveBtn} ${styles.cancelBtn}`}>{commonT('cancelLabel')}</button>
                <button disabled={!!loading} className={styles.saveBtn}>{!!loading ?
                  <CircularProgress color="inherit"/> : commonT('saveLabel')}</button>
              </div>
            </form>
          </div>
        </div>
        <div className={styles.overlay}/>
      </React.Fragment> : null
  )
}

export default Supplementary
