import {useConfig} from "../../Provider/ConfigProvider/ConfigProvider";
import styles from "./DashbordHeader.module.css";
import {ProgressiveImg} from "../Reused";
import LanguagePicker from "../Reused/Header/LanguagePicker"
import {Link, Route} from 'react-router-dom'
import Profile from '../../Assets/personun.png'
import {LogoutApi} from "../../Provider/api";
import {useLanguage} from "../../Provider/langProvider/LangProvider";
import { Helmet } from 'react-helmet'
import {useTranslation} from "react-i18next";

function DashbordHeader({title}) {
  const {dir} = useLanguage()
  const {user: userInfo,loading} = useConfig()
  // const {loading} = LoginUPassState()
  const { t:commonT } = useTranslation('common');

  const name = userInfo?.name ?? userInfo?.username ?? userInfo?.email ?? userInfo?.phone_number ?? ''
  const description = userInfo?.email ?? userInfo?.phone_number ?? ''
  const cover = userInfo?.profile?.cover ?? userInfo?.profile?.dir + userInfo?.profile?.low ?? false
  const {logoutApiChild} = LogoutApi()
  return (
    <nav className={styles.nav}>
      <Helmet>
        <title>{commonT('routes',{returnObjects:true})[title]}</title>
      </Helmet>
      <ul>
        <li className={styles.name}>
          {commonT('routes',{returnObjects:true})[title]}
        </li>
        <li className={styles.name} style={dir === 'rtl' ? {marginRight: 'auto'} : {marginLeft: 'auto'}}>
        </li>
        <Route exact path="/admin/Tracking" render={() => <>
          <li className={`${styles.active_item_menu_top} ${styles.text} ${styles.ml_auto}`}>
            <a href>
              Dashboard
            </a>
          </li>
          <li className={`${styles.active_item_menu_top} ${styles.text}`}>
            <a href>
              Reports
            </a>
          </li>
        </>}/>
        <li className={styles.ml_auto}>
          {/*<UsersNotifications/>*/}
        </li>
        <li>

        </li>
        <li style={{'font-size': '0.8em'}}>
          <LanguagePicker/>
        </li>

        <li className={`${styles.profile_dropdown} ${styles.mobile}`}>
          <li className={`${styles.profile} ${styles.mobile}`}>
            <ProgressiveImg cover={cover} desktop={userInfo?.profile?.location ?? Profile}/>
          </li>
          <span>
            <span>{name}</span>
            <span>{description}</span>
          </span>
          <i className="fas fa-chevron-down"/>
          <ul style={{width: '8em'}}>
            <li>
              <Link onClick={logoutApiChild}>
                Logout
              </Link>
            </li>
            <li>
              <Link to={'/profileSetting'}>
                Profile
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default DashbordHeader;
