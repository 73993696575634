import React from "react";

const CounterDownContext = React.createContext();
const CounterDownContextSetState = React.createContext();


function CounterDownProvider({ children }) {
  const [secs, setSecs] = React.useState(30);
  const [mins, setMins] = React.useState(1);

  return (
    <CounterDownContext.Provider value={{secs , mins}}>
      <CounterDownContextSetState.Provider value={{ setSecs , setMins }}>
        {children}
      </CounterDownContextSetState.Provider>
    </CounterDownContext.Provider>
  );
}


function CounterDownState() {
  const {secs , mins} = React.useContext(CounterDownContext);

  if (secs === undefined || mins === undefined) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return {secs , mins};
}


function CounterDownSetState() {
  const { setSecs , setMins } = React.useContext(CounterDownContextSetState);

  if (setSecs === undefined && setMins === undefined) {
    throw new Error("render <LoginProvider /> at top of the tree");
  }

  return { setSecs , setMins };
}

// counter calculator
function CounterDown(){
    const { secs , mins } = CounterDownState();
    const {setSecs , setMins} = CounterDownSetState();

    const tick = () => {
   
       if (secs === 0 && mins > 0) {
           setMins(mins - 1)
           setSecs(59)
       } else if(secs > 0){
           setSecs(secs - 1)
       }
    }
 
   const resetTime = () => {
       setSecs(30)
       setMins(1)
   }


   return { resetTime , tick} 
}

export { CounterDownState , CounterDown };
export default CounterDownProvider;