import {lazy} from 'react';

function LazyRoute (comp) {
  const component = lazy(comp)
  component.preload = comp
  return component
}
const MainPage = LazyRoute(() => import("components/mainPage/MainPage"));
const SignUp = LazyRoute(() => import("components/LoginComponents/MobileLogin/SignUpEmail/SignUpEmail"));
const DigitalCode = LazyRoute(() => import("components/LoginComponents/MobileLogin/DigitalCode/DigitalCode"));
const Password = LazyRoute(() => import("components/LoginComponents/MobileLogin/EnterPassword/EnterPassword"));
const Availability = LazyRoute(() => import("components/availability/Availability"));
const Faqs = LazyRoute(() => import("components/faqs/Faqs"));
const Collection = LazyRoute(() => import("components/collection/Collection"));
const SingleCollection = LazyRoute(() => import("components/collection/CollectionIndex"));

/**
 *
 * @type {[{path: string, component: *, name: string}]}
 * @param path = pathName
 * @param component = exotic component
 * @param name = ./data.js export [lang][name] = string
 */

const routes = [
  {
    exact: true,
    private: false,
    path: "/login",
    component:SignUp,
    preload: null,
  },
  {
    exact: true,
    private: false,
    path: "/password",
    component:Password,
    preload: null,
  },
  {
    exact: true,
    private: false,
    path: "/code",
    component:DigitalCode,
    preload: null,
  },
  {
    exact: true,
    private: true,
    path: "/",
    title:'dashboard',
    component:MainPage,
    preload: null,
  },
  {
    exact: true,
    private: true,
    path: "/availability",
    title:'availability',
    component:Availability,
    preload: null,
  },
  {
    exact: true,
    private: true,
    path: "/faqs",
    title:'faqs',
    component:Faqs,
    preload: null,
  },
  {
    exact: true,
    private: true,
    path: "/collections",
    title:'collections',
    component:Collection,
    preload: null,
  },
  {
    exact: true,
    private: true,
    path: "/collection/:id",
    title:'collection',
    component:SingleCollection,
    preload: null,
  },
];

export const routesName = routes.map((item) => item?.path);
export default routes;
