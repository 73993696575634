import Compose from "./Routes/Compose";
import LoginProvider from "./Provider/LoginProvider";
import LangProvider from "Provider/langProvider/LangProvider";
import {BrowserRouter} from "react-router-dom";
import ConfigProvider from "Provider/ConfigProvider/ConfigProvider";
import SentryInit from "SentryInit";
import ChangeEmailProvider from "Provider/ChangeEmail/ChangeEmail";
import ChangeInfoProvider from "Provider/ChangeInfo/ChangeInfo";
import ChangePasswordProvider from "Provider/ChangePassword/ChangePassword";
import ChangePhoneNumberProvider from "Provider/ChangePhoneNumber/ChangePhoneNumber";
import CounterDownProvider from "Provider/CounterDown";
import SignInProvider from "Provider/UserNameAndPassword";
import App from "./App";

const providers = [
  LoginProvider,
  ChangeEmailProvider,
  ChangePasswordProvider,
  ChangePhoneNumberProvider,
  CounterDownProvider,
  SignInProvider
];

function AppContext() {
  return (
    <BrowserRouter>
      <SentryInit>
        <LangProvider>
          <LoginProvider>
            <ChangeInfoProvider>
              <ConfigProvider>
                <Compose components={providers}>
                  <App/>
                </Compose>
              </ConfigProvider>
            </ChangeInfoProvider>
          </LoginProvider>
        </LangProvider>
      </SentryInit>
    </BrowserRouter>
  );
}

export default AppContext;
