import styles from "./Error.module.css";
import { useLanguage } from "../../../Provider/langProvider/LangProvider";
import { VscDebugRestart } from "react-icons/vsc";
import { FiAlertTriangle } from 'react-icons/fi'
// FiAlertTriangle
// VscDebugRestart

interface Props {
  fetchData?: () => void;
  message?: string;
}

export const Error = ({fetchData, message}: Props) => {
  const {
    shop: { error, tryAgain },
  } = useLanguage();

  const handleClick = () => {
    fetchData && fetchData();
  };

  return (
    <div className={styles.main}>
      <FiAlertTriangle className={styles.alertIcon}/>
      <div>
        <span className={styles.errorText}>{message ? message :error}</span>
        <div className={styles.tryAgain}>
          <span className={styles.tryAgainText}>{tryAgain}</span>
          <button onClick={handleClick} className={styles.refreshBtn}>
            <VscDebugRestart className={styles.restartIcon}/>
          </button>
        </div>
      </div>
    </div>
  );
};

// export default Error;
