import './i18n';
import * as Sentry from "@sentry/react";
import {Suspense, useEffect} from "react";
import {isMobile} from "react-device-detect"
import {Redirect, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import "./reset.css"
import "./App.css";
import "./styles/globals.css"
import {RTL} from "./components/Reused/MuiRTL/MuiRTL";
import { useLanguage} from "./Provider/langProvider/LangProvider"
import routes from './Routes/mainRoutes'
import PrivateRoute from "./Routes/PrivateRoute";
import ScrollToTop from "./Routes/ScrollToTop";
import ChangeInformationDialog from './components/Supplementary/Supplementary'
import Dashboard from "./components/Dashboard/Dashboard";
import LoadingImg from "./components/LoadingImg/LoadingImg";

const SentryRoute = Sentry.withSentryRouting(Route);

function Wrapper({ ...route }) {
  return route.private === true ? (
    <Dashboard title={route?.title}>
      <PrivateRoute {...route} />
    </Dashboard>
  ) : (
    <SentryRoute {...route} />
  )
}

function App() {
  const { dir } = useLanguage();

  useEffect(() => {
    document.body.className = dir;
  }, [dir]);

  return (
    <>
      <Suspense  fallback={<LoadingImg/>}>
        <ScrollToTop />
        <div dir={dir} className="App" style={isMobile? {userSelect:'none',WebkitTouchCallout:'none',WebkitUserSelect:'none',KhtmlUserSelect:'none',MozUserSelect:'none',msUserSelect:'none',WebkitTapHighlightColor:'rgba(0,0,0,0)'}:{}}>
          <RTL >
            <Switch>
              {routes.map((route, index) => { 
                // TODO PUT PRELOAD IN TRY CATCH AND DO ONCE
                try {
                  route?.preload?.()
                }catch (e) {
                  Sentry.captureException(e)
                }
                return <Wrapper {...route} key={index}/>
              })} 
              <Redirect to="/login" />
            </Switch>
          </RTL>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={dir === 'rtl'}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon
          theme="colored"
        />
        <ChangeInformationDialog />
      </Suspense>
    </>
  );
}

export default App
