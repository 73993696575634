import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react';

const SentryInit = (props) => {
    const history = useHistory()
    useEffect(() => {
        if(process.env.NODE_ENV==="production"){
            Sentry.init({
                dsn: "https://a01bb02c7efb4cd8a3bb5f62fe409178@o1126993.ingest.sentry.io/6168499",
                integrations: [new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                })],
    
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
            });
        }
    }, []);
    return (
        <>
            {props.children}
        </>
    );
}

export default SentryInit;