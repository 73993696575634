import React from "react"
import { Redirect, Route } from "react-router-dom"
import { LoginState } from 'Provider/LoginProvider'
import * as Sentry from "@sentry/react";
const SentryRoute = Sentry.withSentryRouting(Route);

function PrivateRoute (props) {
  const loggedIn = LoginState()
  return loggedIn ? <SentryRoute {...props} /> : <Redirect to={props?.RedirectTo || "/login"} />
}

export default PrivateRoute
